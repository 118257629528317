._common-navigation {
  &_tech-side {
    .navigation__child {
      padding: 8px 12px 8px 60px;
    }

    .sub-nav__title {
      font-size: 16px !important;
    }

    .sub-nav__item {
      margin: 0 !important;
    }
  }
}
