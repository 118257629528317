.mat-button-toggle-group-appearance-standard {
  $root: &;
  border: solid 1px var(--color-primary);

  .mat-button-toggle {
    border: 0 !important;
  }
}

.mat-button-toggle {
  $root: &;

  &.mat-button-toggle-checked {
    background: var(--color-primary);

    .mat-button-toggle-label-content {
      color: #fff;
    }
  }

  .mat-button-toggle-label-content {
    min-width: 60px;
    font-weight: 600;
    font-size: 16px;
    line-height: 35px;
    color: var(--color-primary);
    background: var(--color-primary-rgba-05);
  }
}
