.mat-form-field-appearance-standard {
  .mat-form-field-flex {
    padding-top: 0 !important;
  }
}

.mat-input-element {
  &:disabled {
    color: rgba(0, 0, 0, 0.46);
  }
}

  .mat-form-field-appearance-outline {
  .mat-form-field-flex {
    &:hover {
      .mat-form-field-outline-thick {
        color: var(--color-primary);
      }
    }
  }

  .mat-form-field-invalid {
    .mat-form-field-flex {
      &:hover {
        .mat-form-field-outline-thick {
          color: var(--color-warn);
        }
      }
    }
  }

  .mat-form-field-outline {
    color: rgba(0, 0, 0, 0.38);
  }
}

.mat-form-field-suffix .mat-icon-button {
  height: 1.4em;
}

.form-date {
  &.mat-form-field-appearance-outline {
    .mat-form-field-prefix {
      padding-bottom: 0.7em;
      padding-right: 4px;

      .mat-datepicker-toggle .mat-mdc-button-base {
        width: 1em !important;
        height: 1em !important;
      }
    }

    .mat-mdc-icon-button .mat-mdc-button-touch-target {
      width: 100%;
      height: 100%;
    }
  }
}

._common-advanced-prefix-input {
  .mat-form-field-infix {
    max-width: 162px;
  }
}
._common-advanced-input {
  .mat-form-field-infix {
    max-width: 186px;
  }
}
