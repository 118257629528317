.sub-nav {
  display: inline-block;

  &__item {
    padding: 0.4rem 0.6rem;
    margin-bottom: 0.8rem;
    text-decoration: none;
    cursor: pointer;
    outline: 0;

    &.active {
      font-weight: 900;
      color: var(--color-primary) !important;

      .sub-nav__title {
        color: var(--color-primary) !important;
      }
    }
  }

  &__icon-wrapper {
    --app-mat3-icon-size: 18px;

    width: 18px;
    line-height: 0;
    margin-left: 0;
    margin-right: 16px;
  }
}
