.app-section {
  &.mat-expansion-panel {
    border: solid 2px #e2e2e2;
    border-radius: 4px;
    box-shadow: -10px 10px 20px 0 rgba(0, 0, 0, 0.1);

    @media print {
      box-shadow: none;
    }

    .mat-expansion-panel-header {
      padding: var(--app-section-header-padding, (10px 12px));
      color: var(--color-text-dark);
      font-weight: 600;
      font-size: 20px;
      line-height: 1.2;
      letter-spacing: 0.15px;
      border-bottom: none;
      border-radius: 4px 4px 0 0;

      &.mat-expanded {
        height: auto;
        min-height: 64px;
        border-bottom: solid 2px #e2e2e2;
      }
    }

    .mat-expansion-panel-body {
      padding: 24px;
    }
  }
}
