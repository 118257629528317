@use '../../th-common/scss/main';
@use 'components';
@use 'common';
@use 'elements';
@use '../../th-common/scss/mixins' as m;
@use '../../th-common/scss/variables' as v;

:root {
  --mat3-button-height: 36px;
  --mat3-button-min-width: 100px;
  --mat3-stroked-button-background: var(--color-primary-rgba-05);
  --mat3-button-border-radius: 4px;
  --app-mat3-button-box-shadow: 0 2px 5px #a8a6a680;
  --app-mat3-button-label-transform: uppercase;
  --app-mat3-button-icon-size: 22px;
  --app-mat3-button-icon-margin-right: 10px;
  --app-mat3-expansion-panel-body-border-top: none;
  --app-mat3-expansion-panel-border-radius: 4px;
  --app-input-prefix-icon-font-size: 18px;

  //Table
  --th-table-row-header-padding: 4px 8px 0;
  --th-table-cell-padding: 4px 5px;
  --th-table-row-header-font-size: 14px;
  --th-table-cell-font-size: 14px;
  --th-table-header-border-color: #dbdbdb;
  --th-table-border: none;
  --th-table-border-radius: 4px;
  --th-table-row-border-color: #0000001f;
}

body {
  background: var(--theme-background-gray);
}

// Overrides

.priority-column {
  .th-table-col {
    height: 20px;

    &__icon {
      width: 20px;
      height: 20px;
    }
  }

  .mat-sort-header-arrow {
    display: none;
  }

  .mat-sort-header-container {
    justify-content: center;

    &:hover {
      .th-table-col__icon {
        display: none;
      }

      .mat-sort-header-arrow {
        display: block;
        margin-left: 0;
      }
    }
  }

  .mat-sort-header-sorted {
    .th-table-col__icon {
      display: none;
    }

    .mat-sort-header-arrow {
      display: block;
      margin-left: 0;
    }
  }
}

.forgot-password__link {
  font-weight: 600;
  font-size: 14px;
}

.dashboard-tile__header-icon {
  background: none;
}

.mat-form-field.mat-form-field-appearance-outline {
  $form-field-input-size: 16px;

  font-size: 12px;

  input.mat-input-element {
    margin-top: -0.125em;
  }

  .mat-placeholder-required {
    color: var(--color-warn);
    font-size: 14px;
  }

  &.mat-form-field-can-float.mat-form-field-should-float {
    .mat-form-field-label {
      transform: translateY(-1.84373em) scale(0.75);
    }
  }

  &.form-date {
    .mat-datepicker-toggle {
      color: var(--color-primary);

      .mat-mdc-icon-button {
        width: auto !important;
        min-width: 0 !important;
      }
    }
  }

  .mat-form-field-wrapper {
    font-size: $form-field-input-size;
  }

  .mat-form-field-flex {
    font-size: 10px;
  }

  .mat-input-element {
    font-size: $form-field-input-size;
  }

  .mat-form-field-label {
    mat-label {
      font-size: $form-field-input-size;
    }
  }

  .mat-select {
    font-size: $form-field-input-size;
  }

  .mat-select-arrow-wrapper {
    transform: translateY(-12%);
  }
}

.mat-icon {
  &--small {
    --app-mat3-icon-size: 20px;
  }
}

//button
._common-button {
  &_is-loading {
    .mat-button-wrapper {
      visibility: hidden;
    }
  }

  &--full {
    &.mat-button-base {
      width: 100% !important;
    }
  }

  &--no-focus-state:focus {
    background-color: #fff !important;

    .mat-button-base.mat-flat-button {
      box-shadow: none !important;
    }
  }

  &--circle {
    &.mat-button-base {
      width: 40px;
      min-width: 40px;
      height: 40px;
      padding: 0;
      line-height: 20px;
      border-radius: 50%;
    }
  }

  &--circle-sm {
    --app-mat3-icon-width: 60%;

    &.mat-button-base {
      width: 2.5rem;
      min-width: 2.5rem;
      height: 24px;
      padding: 0;
      line-height: 1.3px;
      border-radius: 50%;
    }
  }

  &--public {
    &.mat-button-base {
      background: #fff;

      &[disabled] {
        background: #fff;

        .mat-button-wrapper {
          color: var(--color-primary);
        }
      }
    }

    .mat-button-wrapper {
      color: var(--color-primary);
    }
  }

  &--white {
    &.mat-button-base {
      background: #fff;
    }

    .mat-button-wrapper {
      color: var(--color-primary);
    }
  }

  &--grid-action-button {
    line-height: 1;
    box-shadow: none;

    &:hover {
      opacity: 1;
    }

    &.mat-button-base {
      min-width: 85px;
      padding: 0 12px;
      background: var(--color-primary);

      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }

      .mat-button-wrapper {
        color: #fff;
      }
    }

    &.mat-flat-button {
      min-width: 85px;
      padding: 0 12px;
      background: #fff;

      &:hover {
        background: #fff;
      }

      .mat-button-wrapper {
        color: var(--color-primary);
      }
    }
  }

  &__vertical-button {
    & ~ & {
      margin-top: 1.2rem;
    }
  }

  &__two-lines {
    position: relative;
    bottom: 8px;

    .mat-button-wrapper {
      height: 28px;
      font-size: 10px;
      line-height: 14px;
    }
  }
}

.inline-buttons {
  display: flex;
  flex-direction: column-reverse;
  gap: 12px;
  align-items: center;
  justify-content: space-between;

  @include m.breakpoint(v.$screen-tablet) {
    flex-direction: row;
  }

  &--right {
    justify-content: flex-end;

    .mat-button-base {
      & ~ & {
        margin-left: 2rem;
      }
    }
  }

  &--center {
    justify-content: center;

    .mat-button-base {
      & ~ & {
        margin-left: 3.2rem;
      }
    }
  }

  &--primary-color {
    color: var(--color-primary);
  }
}

.mat-button.mat-button-disabled,
.mat-icon-button.mat-button-disabled,
.mat-stroked-button.mat-button-disabled,
.mat-flat-button.mat-button-disabled,
.mat-button-disabled {
  cursor: not-allowed !important;
}

// Dashboard tile
$tile-width: 256px;
$action-not-required-padding-left: 2px;

.dashboard-tile {
  $root: &;

  width: $tile-width;
  margin: 0 !important;
  background: var(--color-secondary);
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgba(83, 97, 255, 0.05);
  cursor: pointer;

  &_theme {
    background: var(--color-primary);
  }

  &_theme-indicator-red {
    background-color: var(--color-red-light-background);
    border: solid 2px var(--color-red-help);
  }

  &_theme-red {
    background: var(--color-red-help);
    border: solid 2px var(--color-red-attention);
  }

  &_theme-blue {
    background: var(--color-primary);
    border: solid 2px var(--color-primary);
  }

  &__header {
    display: flex;
    align-items: center;
    padding: 16px;
    color: #fff;
    font-size: 20px;
    border-bottom: 1px solid #fff;

    &--one-line {
      font-size: 2rem;
    }
  }

  &__icon {
    --app-mat3-icon-size: 22px;
    --app-mat3-icon-width: 20px;

    margin-right: 16px;
  }

  &__header-labels-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 29px;
  }

  &__header-label-required {
    margin-top: 4px;
    font-size: 12px;
    font-style: italic;
  }

  &__actions-wrapper {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
    min-width: 0;
    height: 100%;
  }

  &__actions-section {
    align-self: center;
    width: 100%;
    min-width: 0;
    font-size: 12px;
    cursor: default;
  }

  &__action {
    position: relative;
    display: flex;
    gap: 5px;
    align-items: center;
    padding-left: 0;
    line-height: 1;
    cursor: default;

    & ~ & {
      margin-top: 0;
    }

    &:hover {
      text-decoration: none;
    }
  }

  &__action-count {
    min-width: 24px;
    font-weight: 600;
    text-align: right;
  }

  &__action-title {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &__action-icon-alert {
    --app-mat3-icon-size: 12px;
    --app-mat3-icon-width: 16px;
    --app-mat3-icon-height: 12px;

    width: 16px;
    height: 12px;
  }

  &__card-icon {
    --app-mat3-icon-size: 16px;
  }

  &__content {
    display: flex;
    align-items: center;
    height: 106px;
    padding: 12px 16px;
    color: #fff;
    font-size: 1.6rem;
    cursor: pointer;
  }

  &__count-section {
    cursor: pointer;
  }

  &__count {
    font-size: 3.4rem;
  }

  &__loader {
    color: v.$red;
  }

  &--composite {
    width: 320px;
    min-width: $tile-width;

    #{$root}__content {
      cursor: default;
    }

    #{$root}__count-section {
      cursor: pointer;
    }

    #{$root}__action {
      cursor: pointer;
    }
  }

  &_tech-portal {
    width: 270px;
    min-width: 252px;
    margin-right: 1.6rem;
    cursor: default;

    #{$root}__content {
      padding: 22px 16px;
      cursor: default;
    }

    #{$root}__count-section {
      cursor: default;
    }

    #{$root}__action {
      font-size: 14px;
      cursor: pointer;
    }

    #{$root}__actions-section {
      display: flex;
      flex-direction: column;
      gap: 8px;
      justify-content: space-between;
    }
  }

  &__empty-title {
    font-weight: 600;
    font-size: 15px;
  }

  &__empty-sub-title {
    font-size: 14px;
  }

  &--empty-tile {
    background-color: #8f93a3;
    border: dashed 2px #4b526d;;

    #{$root}__count {
      font-size: 34px;
    }

    #{$root}__actions-wrapper {
      gap: 12px;
    }

    #{$root}__actions-section {
      gap: 12px;
      justify-content: flex-start;
    }
  }

  &__empty-image-section {
    --app-mat3-icon-size: 42px;

    min-width: 42px;
    height: 42px;
  }

  &__header-label-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 1.33;
    letter-spacing: 0.14px;
  }

  &__header-label-count {
    font-size: 24px;
  }

  &--simple-tile {
    #{$root}__header {
      align-self: stretch;
      justify-content: center;
      padding: 0.8rem 1.6rem;
      border-bottom: none;
    }

    #{$root}__header-labels-section {
      flex-direction: column;
      gap: 8px;
      justify-content: center;
      height: auto;
      font-size: 18px;
      text-align: center;
    }
  }

  &--indicator-tile {
    width: 100%;
    min-width: 180px;
    height: 96px;
    max-height: 96px;
    margin-right: 0;
    padding: 1rem;
    text-align: center;

    #{$root}__header {
      font-size: 1.5rem;
      border-bottom: none;
    }

    #{$root}__header-label-title {
      height: 48px;
      max-height: 48px;
      margin: 0 0 4px;
      color: var(--color-text-dark);
      font-weight: 600;
      font-size: 18px;
      line-height: 1.33;
      letter-spacing: 0.14px;
    }

    #{$root}__header-label-count {
      font-weight: 600;
      font-size: 24px;
      line-height: 1;
      letter-spacing: 0.18px;
    }
  }
}

._pagination-without-first-last-arrows {
  .pagination__previous {
    .pagination__button:first-child {
      display: none !important;
    }
  }

  .pagination__next {
    .pagination__button:last-child {
      display: none !important;
    }
  }

  .pagination__counter {
    display: none !important;
  }
}

._revert-reset-styles {
  ol,
  ul {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 40px;
  }

  ol {
    list-style-type: decimal;
  }

  ul {
    list-style-type: disc;
  }

  li {
    display: list-item;
    text-align: -webkit-match-parent;
  }

  ::marker {
    text-align: start !important;
    text-align-last: start !important;
    text-transform: none;
    text-indent: 0 !important;
    unicode-bidi: isolate;
    font-variant-numeric: tabular-nums;
  }

  blockquote {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 40px;
    margin-inline-end: 40px;
  }

  q {
    display: inline;
  }

  blockquote::before,
  q::before {
    content: open-quote;
  }

  blockquote::after,
  q::after {
    content: close-quote;
  }

  blockquote,
  q {
    quotes: revert;
  }

  table {
    display: table;
    box-sizing: border-box;
    border-collapse: separate;
    border-spacing: 2px;
  }
}

//dialog
$padding: 16px;
$header-height: 65px;
$dialog-nav-width: 25rem;

.dialog {
  $root: &;

  display: block;
  height: 100%;
  background: #fff;

  @include m.print() {
    width: 100% !important;
  }

  &--side {
    #{$root}__content {
      height: calc(100% - #{$header-height});
      padding: 0 $padding;
    }

    #{$root}__container {
      padding-top: $padding;
    }

    .section {
      &:last-child {
        padding-bottom: 16px;
      }
    }
  }

  &--header-alert {
    #{$root}__content {
      height: calc(100% - 147px);
    }
  }

  &--short {
    width: 55vw;
  }

  &__header {

    @include m.do-not-print();

    padding: 16px;
    //color: var(--color-primary);
    border-bottom: 1px solid v.$header-border;

    &--flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__title {
    display: block;
    margin: 0;
    color: rgba(0, 0, 0, 0.85);

    &--uppercase {
      text-transform: uppercase;
    }

    &--with-subtitle {
      display: flex;
      align-items: flex-start;

      #{$root}__main-title {
        width: calc(#{$dialog-nav-width} + #{$padding});
      }
    }
  }

  &__main-title {
    width: 100%;
    font-size: 22px;

    @include m.breakpoint(v.$screen-tablet) {
      font-size: 30px;
    }
  }

  &__subtitle {
    width: calc(100% - #{$dialog-nav-width} - #{$padding} - #{$padding});
    padding-left: 6px;
    font-size: 14px;
  }

  &__container {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 250px minmax(0, 1fr);
    height: 100%;

    @include m.print() {
      display: block !important;

      ::ng-deep table {
        white-space: normal;
        table-layout: fixed;

        * {
          white-space: normal !important;
        }
      }
    }

    &__grid-wide {
      grid-template-columns: 310px minmax(0, 1fr);
    }

    &__block {
      display: block;
    }

    &--small-gap {
      grid-gap: 4px;
    }
  }

  &__close {
    cursor: pointer;
  }

  &__nav {

    @include m.do-not-print();

    width: $dialog-nav-width;
    min-width: $dialog-nav-width;
    max-width: $dialog-nav-width;
    margin-right: 3.2rem;
    padding: 0 0 $padding $padding;
  }

  &__controls {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    min-width: 305px;
  }

  &__content {
    position: relative;
    width: 100%;
    padding: $padding;

    &--black {
      color: var(--color-text-primary);
    }
  }

  &__switch-header {
    display: flex;
    align-items: center;
  }

  &__same-switch {
    margin-left: 2rem;
  }

  &__submit-button {
    margin: 1rem 0;
  }
}

._common-dialog {
  max-height: 80vh;
}

.cdk-global-overlay-wrapper {
  overflow: auto;

  @include m.breakpoint(v.$screen-tablet-lg) {
    overflow: hidden;
  }
}

// mat form field
._common-mat-form-field {
  &--quick-search {
    .mat-input-element {
      font-size: 12px;
    }

    .mat-form-field-prefix {
      margin-right: 8px;
      padding-bottom: 3px;
    }
  }

  &_grid-search {
    .mat-form-field {
      width: 400px;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }
  }
}

._common-table-td {
  &--cost {
    .mat-form-field-appearance-outline .mat-form-field-wrapper,
    .mat-form-field-wrapper {
      padding-bottom: 0 !important;
    }

    .mat-form-field-infix {
      padding: 0 !important;
      border-top: 0 !important;
    }

    .mat-form-field-wrapper .mat-form-field-underline .mat-form-field-ripple {
      height: 0 !important;
    }

    .mat-form-field-label-wrapper {
      padding: 0 !important;
    }

    .mat-form-field-appearance-outline .mat-form-field-underline,
    .mat-form-field-underline,
    .mat-form-field-wrapper .mat-form-field-underline {
      height: 0 !important;
    }
  }
}

._common-theme-field {
  .mat-form-field-label {
    //color: var(--color-primary);
    color: rgba(0, 0, 0, 0.6);
  }
}

.sub-nav {
  &__title {
    font-size: 20px;
  }

  &__item {
    gap: 8px;
    align-items: center;
    padding: 0 8px;

    &.active {
      border-left: solid 3px var(--color-primary);

      .sub-nav__title {
        color: rgba(0, 0, 0, 0.87) !important;
        font-weight: 400;
      }
    }
  }

  &-actions {
    margin-left: 0;
  }
}

.show-on-tablet {
  display: none;

  @include m.breakpoint(v.$screen-tablet) {
    display: initial;
  }
}

.mat-drawer-container {
  -webkit-overflow-scrolling: auto !important;
}
